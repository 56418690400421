import { render, staticRenderFns } from "./power.vue?vue&type=template&id=63bf3f24&scoped=true&"
import script from "./power.vue?vue&type=script&lang=js&"
export * from "./power.vue?vue&type=script&lang=js&"
import style0 from "./power.vue?vue&type=style&index=0&id=63bf3f24&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63bf3f24",
  null
  
)

export default component.exports