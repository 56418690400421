<template>
  <div class="content_box neirong theme_bg">
    <div class="box_bg">
      <div class="Reset">
      <van-icon name="warning" color="#4C8BF4" class="warning" />
      <p> 该模块仅对专业用户开放，<br/>如有需要请联系 <a href="tel:+0452-2715649">0310-8132202</a></p>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "power",
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    
  },
  filters: {

  }
}
</script>

<style lang="scss" scoped>
.neirong{
  position: relative;
  // background-color: rgba($color: #000000, $alpha: 0.5);
}
.box_bg{
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  // background: rgba(255, 255, 255, 0.05);
  // backdrop-filter: blur(1px);
  // z-index: 1000;
}
.Reset{
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #333;
  width: 90%;
  p{
    font-size: 22px;
    margin-top: 10px;
  }
  .warning{
    font-size: 28px;
  }
}
</style>